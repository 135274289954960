.container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-content: center;
}

.card {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 5px solid green;
  padding:20px;
  align-items: center;
  align-self: center;
  justify-content: center;
  justify-items: center;
  width: 50%;
  margin: 30px;
  border-radius: 20px;

}
.title {
  width: 100px;
  
}
.input {
  width: 50%;
  padding: 10px;
  margin: 10px;
  border: 1px solid green;
  border-radius:10px;
}

.submit {
  width: 50%;
  padding: 10px;
  background-color: green;
  border: none;
  border-radius: 40px;
  font-size: larger;
  font-weight: 500;
  color: white;
  cursor: pointer;
}